import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect } from 'react'
import Link from 'src/components/ui/Link'
import { useScrollEvent } from 'src/sdk/analytics/hooks/useScrollEvent'
import '../styles/pages/404.scss'
import { RenderClient } from 'src/components/RenderClient'

function Page() {
  const { isMobile } = useBreakpoint()

  useEffect(() => {
    window.addEventListener('scroll', useScrollEvent)

    return () => {
      window.removeEventListener('scroll', useScrollEvent)
    }
  }, [])

  const linkData = [
    {
      text: 'Home',
      url: '/',
    },
    {
      text: 'Prodotti per cane',
      url: '/cane',
    },
    {
      text: 'Prodotti per gatto',
      url: '/gatto',
    },
    {
      text: 'Prodotti per pesci',
      url: '/pesci',
    },
    {
      text: 'Prodotti per roditori',
      url: '/roditori',
    },
    {
      text: 'Prodotti per tartarughe',
      url: '/tartarughe',
    },
    {
      text: 'Prodotti per uccelli',
      url: '/uccelli',
    },
    {
      text: 'Prodotti per rettili',
      url: '/rettili',
    },
    // {
    //   text: 'Sitemap',
    //   url: '/sitemap.xml',
    // },
    {
      text: 'Assistenza clienti',
      url: 'https://my.arcaplanet.it/assistenza/s/contactsupport',
    },
  ]

  return (
    <RenderClient>
      <GatsbySeo />
      <section className="not-found">
        <div className="back-card">
          <p className="back-card__text--top-message">Codice errore: 404</p>
          <h1 className="back-card__text--title">Oops!</h1>
          <h2 className="back-card__text--sub-title">
            Non troviamo la pagina che stai cercando.
          </h2>
          <div className="back-card__links-container">
            {linkData.map((item) => (
              <>
                <Link className="back-card__text--link" as="a" href={item.url}>
                  {item.text}
                </Link>
                <br />
              </>
            ))}
            {/* Customer support link still without the correct path. The page is not yet created */}
          </div>
          <div className="back-card__button-place">
            <Link
              className="back-card__button-place--button-link"
              as="a"
              href="/"
            >
              <button className="back-card__button">
                {isMobile
                  ? 'Visita la nostra homepage'
                  : 'Visita la nostra Home'}
              </button>
            </Link>
          </div>
        </div>
      </section>
    </RenderClient>
  )
}

export default Page
